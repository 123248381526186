import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderItem } from "../features/payment/paymentSlice";
import { Money } from "../proto/vseth/type/money_pb";
import { getFrancs } from "../util/proto";
import { DataTable, DataTableSortStatus } from "mantine-datatable";

interface Props {
    orderItems: OrderItem[];
}

const orderItemPriceFormatter = (
    row: OrderItem,
    rowIndex: number
) => getFrancs(row.itemAmount);

const sortPrice = (
    aRow: OrderItem,
    bRow: OrderItem
): number => {
    if (!aRow.itemAmount || !bRow.itemAmount) {
        return 0;
    }
    return aRow.itemAmount.cents - bRow.itemAmount.cents;
};

export const OrderItemsTable: React.FC<Props> = ({ orderItems }) => {
    const { t } = useTranslation();

    const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'itemAmount', direction: 'asc' });

    const [records, setRecords] = useState(orderItems.sort(sortPrice));

    useEffect(() => {
        const data = orderItems.sort(sortPrice);
        setRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);

    const columns = [
        {
            accessor: 'displayName',
            title: t("OrderItemsTable.item"),
        },
        {
            accessor: 'itemAmount',
            title: t("OrderItemsTable.price"),
            render: orderItemPriceFormatter,
            sort: sortPrice,
            sortable: true,
        },
    ];

    return (
        <DataTable
            striped
            highlightOnHover
            withBorder
            idAccessor="name"
            records={records}
            columns={columns}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
        />
    );
};
